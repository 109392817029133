<template>
	<div :class="['slide', 'slide__single', {'slide__single--collapse' : collapse}]">
		<slot></slot>
	</div>
</template>

<script>
export default {
  props: {
		collapse: Boolean,
  },
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.slide__single {
	height: var(--slider-container-mobile-height);
	width: var(--slider-container-mobile-width);
	padding: var(--slider-container-mobile-padding);
	position: absolute;
	left: 100%;
	top: 0px;
	@include breakpoint(md){
		height: var(--slider-container-height);
		width: var(--slider-container-width);
		padding: var(--slider-container-padding);
	}
}
.slider--collapse .slide__single{
	height: var(--slider-container-collapse-height);
	width: var(--slider-container-collapse-width);
	padding: var(--slider-container-collapse-padding);
}

@-webkit-keyframes slideIn {
    100% { left: 0; }
}
@keyframes slideIn {
    100% { left: 0; }
}
.slideInRight {
	left: -100%;
	-webkit-animation: slideIn 1s forwards;
	animation: slideIn 1s forwards;
}
.slideInLeft {
	left: 100%;
	-webkit-animation: slideIn 1s forwards;
	animation: slideIn 1s forwards;
}
@-webkit-keyframes slideOutLeft {
    100% { left: -100%; }
}
@keyframes slideOutLeft {
    100% { left: -100%; }
}
.slideOutLeft {
	-webkit-animation: slideOutLeft 1s forwards;
	animation: slideOutLeft 1s forwards;
}
.slideOutRight {
	-webkit-animation: slideOutRight 1s forwards;
	animation: slideOutRight 1s forwards;
}
@-webkit-keyframes slideOutRight {
    100% { left: 100%; }
}
@keyframes slideOutRight {
    100% { left: 100%; }
}
</style>

<!-- Used in : AR, ED -->